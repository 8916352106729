import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue";
import { ref, onMounted, reactive } from 'vue';
import { updatePriceEstimate } from '@/api/order';
export default {
  __name: 'dialog-updatePriceEstimate',
  emits: ['restGetOrderInfo'],

  setup(__props, {
    expose: __expose,
    emit: __emit
  }) {
    const emit = __emit;

    const generateFrom = () => {
      return {
        kp_cost: null,
        cj_cost: null,
        ygs_cost: null,
        cy_cost: null,
        ltby_cost: null,
        expense: null,
        discount: null
      };
    };

    const orderInfo = ref();
    const formModel = reactive(generateFrom());
    const DialogVisible = ref(false);

    const showModal = data => {
      console.log(data.records[0]);
      orderInfo.value = data;
      var Keys = Object.keys(formModel);

      for (var key in data.records[0]) {
        if (Keys.indexOf(key) > -1) {
          console.log('key----' + key);
          formModel[key] = data.records[0][key];
        }
      }

      console.log(formModel);
      DialogVisible.value = true;
    }; // 上传附加费用


    const updatePriceEstimateFn = async (name, value) => {
      var data = {
        orderId: orderInfo.value.orderId,
        [name]: value
      };

      try {
        const resData = await updatePriceEstimate(data);

        if (resData.status === 'success') {} else {}
      } catch (err) {
        console.log(err);
      }
    };

    const priceEstimate = ref();

    const onSubmit = () => {
      DialogVisible.value = false;
      priceEstimate.value = formModel;

      for (var item in priceEstimate.value) {
        if (priceEstimate.value[item]) {
          updatePriceEstimateFn(item, priceEstimate.value[item]);
        }
      }

      emit('restGetOrderInfo');
    }; // 初始化


    const init = async () => {};

    onMounted(() => {
      init();
    });

    __expose({
      showModal
    });

    return (_ctx, _cache) => {
      const _component_el_input = _resolveComponent("el-input");

      const _component_el_form_item = _resolveComponent("el-form-item");

      const _component_el_button = _resolveComponent("el-button");

      const _component_el_form = _resolveComponent("el-form");

      const _component_el_dialog = _resolveComponent("el-dialog");

      return _openBlock(), _createBlock(_component_el_dialog, {
        modelValue: DialogVisible.value,
        "onUpdate:modelValue": _cache[7] || (_cache[7] = $event => DialogVisible.value = $event),
        title: "附加费用",
        "align-center": ""
      }, {
        default: _withCtx(() => [_createVNode(_component_el_form, {
          model: formModel,
          "label-width": "auto",
          style: {
            "max-width": "600px"
          }
        }, {
          default: _withCtx(() => [_createVNode(_component_el_form_item, {
            label: "空跑费"
          }, {
            default: _withCtx(() => [_createVNode(_component_el_input, {
              modelValue: formModel.kp_cost,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => formModel.kp_cost = $event),
              modelModifiers: {
                number: true
              }
            }, null, 8, ["modelValue"])]),
            _: 1
          }), _createVNode(_component_el_form_item, {
            label: "超距费"
          }, {
            default: _withCtx(() => [_createVNode(_component_el_input, {
              modelValue: formModel.cj_cost,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => formModel.cj_cost = $event),
              modelModifiers: {
                number: true
              }
            }, null, 8, ["modelValue"])]),
            _: 1
          }), _createVNode(_component_el_form_item, {
            label: "原水费"
          }, {
            default: _withCtx(() => [_createVNode(_component_el_input, {
              modelValue: formModel.ygs_cost,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => formModel.ygs_cost = $event),
              modelModifiers: {
                number: true
              }
            }, null, 8, ["modelValue"])]),
            _: 1
          }), _createVNode(_component_el_form_item, {
            label: "超员费"
          }, {
            default: _withCtx(() => [_createVNode(_component_el_input, {
              modelValue: formModel.cy_cost,
              "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => formModel.cy_cost = $event),
              modelModifiers: {
                number: true
              }
            }, null, 8, ["modelValue"])]),
            _: 1
          }), _createVNode(_component_el_form_item, {
            label: "楼梯搬运费"
          }, {
            default: _withCtx(() => [_createVNode(_component_el_input, {
              modelValue: formModel.ltby_cost,
              "onUpdate:modelValue": _cache[4] || (_cache[4] = $event => formModel.ltby_cost = $event),
              modelModifiers: {
                number: true
              }
            }, null, 8, ["modelValue"])]),
            _: 1
          }), _createVNode(_component_el_form_item, {
            label: "工费"
          }, {
            default: _withCtx(() => [_createVNode(_component_el_input, {
              modelValue: formModel.expense,
              "onUpdate:modelValue": _cache[5] || (_cache[5] = $event => formModel.expense = $event),
              modelModifiers: {
                number: true
              }
            }, null, 8, ["modelValue"])]),
            _: 1
          }), _createVNode(_component_el_form_item, {
            label: "优惠"
          }, {
            default: _withCtx(() => [_createVNode(_component_el_input, {
              modelValue: formModel.discount,
              "onUpdate:modelValue": _cache[6] || (_cache[6] = $event => formModel.discount = $event),
              modelModifiers: {
                number: true
              }
            }, null, 8, ["modelValue"])]),
            _: 1
          }), _createVNode(_component_el_form_item, null, {
            default: _withCtx(() => [_createVNode(_component_el_button, {
              type: "primary",
              onClick: onSubmit
            }, {
              default: _withCtx(() => [_createTextVNode("确定")]),
              _: 1
            }), _createVNode(_component_el_button, null, {
              default: _withCtx(() => [_createTextVNode("取消")]),
              _: 1
            })]),
            _: 1
          })]),
          _: 1
        }, 8, ["model"])]),
        _: 1
      }, 8, ["modelValue"]);
    };
  }

};