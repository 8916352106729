import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue";
const _hoisted_1 = {
  class: "dialog-footer"
};
import { ref, onMounted } from 'vue';
import { getWorkersList } from '@/api/afterSales'; // 获取选择师傅

import { addOrderSF } from '@/api/order';
import { ElMessage } from 'element-plus'; // defineEmits

export default {
  __name: 'dialog-selectWorker',
  emits: ['restGetOrderInfo'],

  setup(__props, {
    expose: __expose,
    emit: __emit
  }) {
    const emit = __emit;
    const workersList = ref();
    const workersIDList = ref(); // 订单中 已经选中的师傅

    const orderInfo = ref();
    const selectWorker = []; // 选中的师傅

    const DialogVisible = ref(false);

    const showModal = data => {
      orderInfo.value = data;
      workersIDList.value = [];
      workersIDList.value = data.workersInfo.map(item => {
        return item.workerId;
      });
      selectWorker.value = [];
      getWorkersData();
      DialogVisible.value = true;
    }; // 获取师傅


    const getWorkers = ref({
      job: '上门师傅',
      sign: '小程序',
      storeId: null
    });

    const getWorkersData = async () => {
      try {
        getWorkers.value.storeId = orderInfo.value.storeId;
        const resData = await getWorkersList(getWorkers.value);

        if (resData.status === 'success') {
          workersList.value = resData.data;
        } else {
          console.log('获取师傅数据失败了。。。');
        }
      } catch (err) {
        console.log(err);
      }
    }; // 获取选中的师傅


    const handleSelectionChange = val => {
      selectWorker.value = val.map(item => {
        return { ...item,
          orderId: orderInfo.value.orderId
        };
      });
    }; // 上传师傅


    const addOrderSFFn = async data => {
      try {
        const resData = await addOrderSF(data);

        if (resData.status === 'success') {
          emit('restGetOrderInfo');
          ElMessage({
            message: '上传师傅成功',
            type: 'success'
          });
        } else {
          ElMessage.error('上传师傅失败了。。。');
        }
      } catch (err) {
        console.log(err);
      }
    }; // 将已经订单已经选中的师傅给禁用


    const selectable = row => !workersIDList.value.includes(row.workerId);

    const confirm = () => {
      if (!workersIDList.value.length > 0) {
        selectWorker.value[0].leader = 1;
      }

      addOrderSFFn({
        workersInfo: selectWorker.value
      });
      DialogVisible.value = false;
    }; // 初始化


    const init = async () => {};

    onMounted(() => {
      init();
    });

    __expose({
      showModal
    });

    return (_ctx, _cache) => {
      const _component_el_table_column = _resolveComponent("el-table-column");

      const _component_el_avatar = _resolveComponent("el-avatar");

      const _component_el_table = _resolveComponent("el-table");

      const _component_el_button = _resolveComponent("el-button");

      const _component_el_dialog = _resolveComponent("el-dialog");

      return _openBlock(), _createBlock(_component_el_dialog, {
        modelValue: DialogVisible.value,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => DialogVisible.value = $event),
        title: "选择师傅",
        "align-center": ""
      }, {
        footer: _withCtx(() => [_createElementVNode("div", _hoisted_1, [_createVNode(_component_el_button, {
          type: "primary",
          onClick: confirm
        }, {
          default: _withCtx(() => [_createTextVNode(" 确定 ")]),
          _: 1
        })])]),
        default: _withCtx(() => [_createVNode(_component_el_table, {
          data: workersList.value,
          style: {
            "width": "100%"
          },
          height: "250",
          ref: "workerRef",
          onSelectionChange: handleSelectionChange
        }, {
          default: _withCtx(() => [_createVNode(_component_el_table_column, {
            type: "selection",
            width: "55",
            selectable: selectable
          }), _createVNode(_component_el_table_column, {
            prop: "ename",
            label: "名字"
          }), _createVNode(_component_el_table_column, {
            label: "头像"
          }, {
            default: _withCtx(scope => [_createVNode(_component_el_avatar, {
              src: scope.row.avatar
            }, null, 8, ["src"])]),
            _: 1
          })]),
          _: 1
        }, 8, ["data"])]),
        _: 1
      }, 8, ["modelValue"]);
    };
  }

};