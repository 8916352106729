import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock } from "vue";
const _hoisted_1 = {
  class: "dialog-footer"
};
import { ref, reactive, onMounted } from 'vue';
import dialogSelectShop from './dialog-selectShop.vue'; // 选择商品弹窗

import { getToolsList, getDtdtimes, fullOrderInfo, getOrderInfo, delOrderGoods, delOrderSF } from '@/api/order';
import dialogUpdatePriceEstimate from './dialog-updatePriceEstimate.vue'; // 附加费用

import dialogSelectWorker from './dialog-selectWorker.vue';
import { ElMessage } from 'element-plus';
export default {
  __name: 'dialog-completeOrder',
  emits: ['restSubmit'],

  setup(__props, {
    expose: __expose,
    emit: __emit
  }) {
    const title = ref('完善订单');
    const emit = __emit;
    const toolsvalue = ref([]); // 已选工具

    const orderInfo = ref();

    const generateFrom = () => {
      return {
        address: '',
        appointment_time: '',
        content: null,
        name: '',
        orderId: null,
        phone: '',
        tool: ''
      };
    };

    const timevalue = ref(); // 上门时间

    const formModel = reactive(generateFrom());
    const DialogVisible = ref(false);

    const showModal = data => {
      orderInfo.value = data;
      title.value = data.order_status === 2 ? '完善订单' : '修改订单';
      toolsvalue.value = null;
      DialogVisible.value = true;
      getToolsListFn();
      getDtdtimesFn();
      getOrderInfoFn(); // 获取订单详情
    };

    const rules = ref({
      buyer_type: [{
        required: true,
        message: '请输入',
        trigger: 'blur'
      }]
    });
    const formRef = ref(); // 获取订单详情 getOrderInfo

    const goodsInfo = ref();

    const getOrderInfoFn = async () => {
      var data = {
        orderId: orderInfo.value.orderId,
        storeId: orderInfo.value.storeId,
        yearly_number: 0
      };

      try {
        const resData = await getOrderInfo(data);

        if (resData.status === 'success') {
          var _resData$data$records, _resData$data$records2, _resData$data$records3;

          var Keys = Object.keys(formModel);

          for (var key in resData.data) {
            if (Keys.indexOf(key) > -1) {
              formModel[key] = resData.data[key];
            }
          }

          formModel.tool = (_resData$data$records = resData.data.records[0]) === null || _resData$data$records === void 0 ? void 0 : _resData$data$records.tool;
          formModel.content = (_resData$data$records2 = resData.data.records[0]) === null || _resData$data$records2 === void 0 ? void 0 : _resData$data$records2.content;
          goodsInfo.value = resData.data.goodsInfo;
          orderInfo.value = resData.data;
          toolsvalue.value = (_resData$data$records3 = resData.data.records[0].tool) === null || _resData$data$records3 === void 0 ? void 0 : _resData$data$records3.split(',');
          timevalue.value = [resData.data.appointment_time.slice(5, 11), resData.data.appointment_time.slice(12, 25)];
        } else {
          console.log('获取订单详情数据失败了。。。');
        }
      } catch (err) {
        console.log(err);
      }
    }; // 上门时间


    const dtdtimesdata = ref();

    const getDtdtimesFn = async () => {
      try {
        const resData = await getDtdtimes();

        if (resData.status === 'success') {
          dtdtimesdata.value = resData.data.dates.map(item => {
            return {
              value: item,
              label: item,
              children: Object.values(resData.data.times).map(item1 => {
                return {
                  value: item1,
                  label: item1
                };
              })
            };
          }); // dtdtimesdata.value = resData.data
        } else {
          console.log('获取师傅数据失败了。。。');
        }
      } catch (err) {
        console.log(err);
      }
    };

    const cascaderhandleChange = e => {
      formModel.appointment_time = e.join(' ');
    }; // 打开选择师傅弹窗


    const dialogSelectWorkerRef = ref();

    const openSelectWorker = () => {
      dialogSelectWorkerRef.value.showModal(orderInfo.value);
    }; // 删除师傅


    const delOrderSFFn = async () => {
      try {
        var workersInfo = orderInfo.value.workersInfo.map(item => {
          return {
            orderId: orderInfo.value.orderId,
            workerId: item.workerId
          };
        });
        var data = {
          workersInfo
        };
        const resData = await delOrderSF(data);

        if (resData.status === 'success') {
          getOrderInfoFn();
          ElMessage({
            message: '删除师傅成功',
            type: 'success'
          });
        } else {
          ElMessage.error('删除师傅失败了。。。');
        }
      } catch (err) {
        console.log(err);
      }
    }; // 获取携带工具


    const toolsList = ref();

    const getToolsListFn = async () => {
      try {
        const resData = await getToolsList();

        if (resData.status === 'success') {
          var tools = resData.data.其他工具 + ',' + resData.data.搬移工具 + ',' + resData.data.检修工具 + ',' + resData.data.清洁工具 + ',' + resData.data.通用工具;
          toolsList.value = tools.split(',');
        } else {
          console.log('获取师傅数据失败了。。。');
        }
      } catch (err) {
        console.log(err);
      }
    }; // 打开选择商品弹窗


    const dialogSelectShopRef = ref();

    const openSelectShop = () => {
      dialogSelectShopRef.value.showModal(orderInfo.value);
    }; // 打开附加费用


    const dialogUpdatePriceEstimateRef = ref();

    const openUpdatePriceEstimate = () => {
      dialogUpdatePriceEstimateRef.value.showModal(orderInfo.value);
    }; // 提交完善订单


    const fullOrderInfoFn = async data => {
      try {
        const resData = await fullOrderInfo(data);

        if (resData.status === 'success') {} else {
          console.log('提交完善订单失败了。。。');
        }
      } catch (err) {
        console.log(err);
      }
    }; // 提交商品后重新获取订单信息


    const restGetOrderInfo = () => {
      getOrderInfoFn();
    }; // 删除商品


    const deleteShop = async serviceordersGoodsId => {
      try {
        const resData = await delOrderGoods({
          serviceorders_goodsId: serviceordersGoodsId
        });

        if (resData.status === 'success') {
          getOrderInfoFn();
          ElMessage({
            message: '删除商品成功',
            type: 'success'
          });
        } else {
          ElMessage.error('删除商品失败了。。。');
        }
      } catch (err) {
        console.log(err);
      }
    };

    const confirm = () => {
      console.log(orderInfo.value.workersInfo);

      if (orderInfo.value.workersInfo.length > 0) {
        if (toolsvalue.value) {
          var _Object$values;

          formModel.tool = (_Object$values = Object.values(toolsvalue.value)) === null || _Object$values === void 0 ? void 0 : _Object$values.join(',');
        } // 提交订单


        fullOrderInfoFn(formModel);
        DialogVisible.value = false;
        emit('restSubmit');
      } else {
        ElMessage.error('请选择上门师傅');
      }
    }; // 初始化


    const init = async () => {// await getWorkersData()
    };

    onMounted(() => {
      init();
    });

    __expose({
      showModal
    });

    return (_ctx, _cache) => {
      const _component_el_cascader = _resolveComponent("el-cascader");

      const _component_el_form_item = _resolveComponent("el-form-item");

      const _component_el_button = _resolveComponent("el-button");

      const _component_el_popconfirm = _resolveComponent("el-popconfirm");

      const _component_el_table_column = _resolveComponent("el-table-column");

      const _component_el_avatar = _resolveComponent("el-avatar");

      const _component_el_table = _resolveComponent("el-table");

      const _component_el_checkbox = _resolveComponent("el-checkbox");

      const _component_el_checkbox_group = _resolveComponent("el-checkbox-group");

      const _component_el_input = _resolveComponent("el-input");

      const _component_el_image = _resolveComponent("el-image");

      const _component_Delete = _resolveComponent("Delete");

      const _component_el_icon = _resolveComponent("el-icon");

      const _component_el_col = _resolveComponent("el-col");

      const _component_el_row = _resolveComponent("el-row");

      const _component_el_form = _resolveComponent("el-form");

      const _component_el_dialog = _resolveComponent("el-dialog");

      return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(_component_el_dialog, {
        modelValue: DialogVisible.value,
        "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => DialogVisible.value = $event),
        title: title.value,
        "align-center": ""
      }, {
        footer: _withCtx(() => [_createElementVNode("div", _hoisted_1, [_createVNode(_component_el_button, {
          type: "primary",
          onClick: confirm
        }, {
          default: _withCtx(() => [_createTextVNode(" 确定 ")]),
          _: 1
        })])]),
        default: _withCtx(() => [_createVNode(_component_el_form, {
          model: formModel,
          "label-width": "auto",
          rules: rules.value,
          ref_key: "formRef",
          ref: formRef
        }, {
          default: _withCtx(() => [_createVNode(_component_el_row, null, {
            default: _withCtx(() => [_createVNode(_component_el_col, {
              span: 24
            }, {
              default: _withCtx(() => [_createVNode(_component_el_form_item, {
                label: "上门时间",
                prop: "appointment_time",
                "label-width": _ctx.formLabelWidth
              }, {
                default: _withCtx(() => [_createVNode(_component_el_cascader, {
                  modelValue: timevalue.value,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => timevalue.value = $event),
                  options: dtdtimesdata.value,
                  onChange: cascaderhandleChange
                }, null, 8, ["modelValue", "options"])]),
                _: 1
              }, 8, ["label-width"]), _createVNode(_component_el_form_item, {
                label: "已选师傅",
                prop: "receive_mail",
                "label-width": _ctx.formLabelWidth
              }, {
                default: _withCtx(() => [_createVNode(_component_el_button, {
                  onClick: openSelectWorker,
                  type: "primary"
                }, {
                  default: _withCtx(() => [_createTextVNode(" 选择师傅 ")]),
                  _: 1
                }), _createVNode(_component_el_popconfirm, {
                  title: "确定删除所有师傅?",
                  onConfirm: delOrderSFFn
                }, {
                  reference: _withCtx(() => [_createVNode(_component_el_button, {
                    type: "danger"
                  }, {
                    default: _withCtx(() => [_createTextVNode(" 一键删除 ")]),
                    _: 1
                  })]),
                  _: 1
                }), _createVNode(_component_el_table, {
                  data: orderInfo.value.workersInfo,
                  style: {
                    "width": "100%"
                  },
                  height: "250"
                }, {
                  default: _withCtx(() => [_createVNode(_component_el_table_column, {
                    prop: "ename",
                    label: "名字"
                  }), _createVNode(_component_el_table_column, {
                    label: "头像"
                  }, {
                    default: _withCtx(scope => [_createVNode(_component_el_avatar, {
                      src: scope.row.avatar
                    }, null, 8, ["src"])]),
                    _: 1
                  }), _createVNode(_component_el_table_column, {
                    label: "负责人"
                  }, {
                    default: _withCtx(scope => [_createElementVNode("text", null, _toDisplayString(scope.row.leader ? '负责人' : ''), 1)]),
                    _: 1
                  })]),
                  _: 1
                }, 8, ["data"])]),
                _: 1
              }, 8, ["label-width"]), _createVNode(_component_el_form_item, {
                label: "携带工具",
                "label-width": _ctx.formLabelWidth
              }, {
                default: _withCtx(() => [_createVNode(_component_el_checkbox_group, {
                  modelValue: toolsvalue.value,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => toolsvalue.value = $event),
                  size: "small"
                }, {
                  default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(toolsList.value, (item, index) => {
                    return _openBlock(), _createBlock(_component_el_checkbox, {
                      label: item,
                      value: item,
                      border: "",
                      key: index,
                      style: {
                        "margin-bottom": "10px"
                      }
                    }, null, 8, ["label", "value"]);
                  }), 128))]),
                  _: 1
                }, 8, ["modelValue"])]),
                _: 1
              }, 8, ["label-width"]), _createVNode(_component_el_form_item, {
                label: "特别提示",
                "label-width": _ctx.formLabelWidth
              }, {
                default: _withCtx(() => [_createVNode(_component_el_input, {
                  modelValue: formModel.content,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => formModel.content = $event),
                  size: "large",
                  placeholder: "请输入特别提示",
                  clearable: "",
                  type: "textarea"
                }, null, 8, ["modelValue"])]),
                _: 1
              }, 8, ["label-width"]), _createVNode(_component_el_form_item, {
                label: "已选商品",
                prop: "receive_mail",
                "label-width": _ctx.formLabelWidth
              }, {
                default: _withCtx(() => [_createVNode(_component_el_button, {
                  onClick: openSelectShop,
                  type: "primary"
                }, {
                  default: _withCtx(() => [_createTextVNode(" 选择商品 ")]),
                  _: 1
                }), _createVNode(_component_el_table, {
                  data: goodsInfo.value,
                  style: {
                    "width": "100%"
                  },
                  height: "250"
                }, {
                  default: _withCtx(() => [_createVNode(_component_el_table_column, {
                    prop: "type",
                    label: "类型"
                  }), _createVNode(_component_el_table_column, {
                    prop: "subject",
                    label: "商品名称"
                  }), _createVNode(_component_el_table_column, {
                    prop: "sortName",
                    label: "型号"
                  }), _createVNode(_component_el_table_column, {
                    label: "图片"
                  }, {
                    default: _withCtx(scope => [_createVNode(_component_el_image, {
                      style: {
                        "width": "80px",
                        "height": "80px"
                      },
                      src: scope.row.sortPhotoUrls
                    }, null, 8, ["src"])]),
                    _: 1
                  }), _createVNode(_component_el_table_column, {
                    label: "数量"
                  }, {
                    default: _withCtx(scope => [_createElementVNode("text", null, _toDisplayString(scope.row.quantity), 1)]),
                    _: 1
                  }), _createVNode(_component_el_table_column, {
                    prop: "sortPrice",
                    label: "价格"
                  }), _createVNode(_component_el_table_column, {
                    prop: "discountedPrice",
                    label: "优惠后价格"
                  }), _createVNode(_component_el_table_column, {
                    prop: "acTitle",
                    label: "活动"
                  }), _createVNode(_component_el_table_column, {
                    prop: "sortPriceAll",
                    label: "总价"
                  }), _createVNode(_component_el_table_column, {
                    prop: "discountedPriceAll",
                    label: "优惠后总价"
                  }), _createVNode(_component_el_table_column, {
                    label: "删除商品"
                  }, {
                    default: _withCtx(scope => [_createVNode(_component_el_button, {
                      circle: "",
                      size: "large",
                      style: {
                        "margin-left": "10px"
                      },
                      onClick: $event => deleteShop(scope.row.serviceorders_goodsId)
                    }, {
                      icon: _withCtx(() => [_createVNode(_component_el_icon, {
                        size: 20
                      }, {
                        default: _withCtx(() => [_createVNode(_component_Delete)]),
                        _: 1
                      })]),
                      _: 2
                    }, 1032, ["onClick"])]),
                    _: 1
                  })]),
                  _: 1
                }, 8, ["data"])]),
                _: 1
              }, 8, ["label-width"]), _createVNode(_component_el_form_item, {
                label: "附加费用",
                "label-width": _ctx.formLabelWidth
              }, {
                default: _withCtx(() => [_createVNode(_component_el_button, {
                  type: "primary",
                  style: {
                    "margin-left": "10px"
                  },
                  onClick: openUpdatePriceEstimate
                }, {
                  default: _withCtx(() => [_createTextVNode("查看详情")]),
                  _: 1
                })]),
                _: 1
              }, 8, ["label-width"])]),
              _: 1
            })]),
            _: 1
          })]),
          _: 1
        }, 8, ["model", "rules"])]),
        _: 1
      }, 8, ["modelValue", "title"]), _createVNode(dialogUpdatePriceEstimate, {
        ref_key: "dialogUpdatePriceEstimateRef",
        ref: dialogUpdatePriceEstimateRef,
        onRestGetOrderInfo: restGetOrderInfo
      }, null, 512), _createVNode(dialogSelectShop, {
        ref_key: "dialogSelectShopRef",
        ref: dialogSelectShopRef,
        onRestGetOrderInfo: restGetOrderInfo
      }, null, 512), _createVNode(dialogSelectWorker, {
        ref_key: "dialogSelectWorkerRef",
        ref: dialogSelectWorkerRef,
        onRestGetOrderInfo: restGetOrderInfo
      }, null, 512)], 64);
    };
  }

};